import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer as FooterWrapper, Social, Link } from "@phillips/seldon";
import PhillipsModal from "../PhillipsModal/PhillipsModal";
import SubscribeToNewsletter from "./SubscribeToNewsletter";
import setI18nLabel, { setI18nLinkLanguage } from "../../utils/getI18nLabel";
import { useActionUpdatesRoute } from "./hooks";

const navigationConfig = [
  { href: "/location", label: "locations" }, 
  { href: "/press", label: "press" }, 
  { href: "/careers", label: "careers" }, 
  { href: "/about/privacy", label: "privacyPolicy" }, 
  { href: "/about/cookiepolicy", label: "cookiePolicyLabel"}, 
  { href: "/about/modernslaverypolicy", label: "modernSlaveryPolicy"}
];

const Footer = () => {
  const phillipsEvents = useRef(null);
  const { language, location } = useSelector(store => store);
  const [showWeChatQR, setShowWeChatQr] = useState(false);
  const [showRedQR, setRedQr] = useState(false);
  
  const getI18nLink = setI18nLinkLanguage(language);

  const dispatch = useDispatch();
  const getI18nLabel = setI18nLabel(language);
  useActionUpdatesRoute({ dispatch, phillipsEvents, language, location });

  const handleWeChatQr = (e) => {
    e.preventDefault();
    setShowWeChatQr((prev) => !prev);
  };

  const handleRedQr = (e) => {
    e.preventDefault();
    setRedQr((prev) => !prev);
  };

  const socialLinks = [
    { href: "https://www.instagram.com/phillipsauction/", name: "insta", src: "footer-instagram-new", onClick: () => {} },
    { href: "https://www.linkedin.com/company/phillips/", name: "linkedin", src: "footer-linkedin", onClick: () => {} },
    { href: "/", name: "wechat", src: "footer-wechat-new", onClick: handleWeChatQr },
    { href: "/", name: "red", src: "footer-red", onClick: handleRedQr },
    { href: "https://www.facebook.com/phillipsauction/", name: "facebook", src: "footer-facebook", onClick: () => {} }
  ];

  const navigationComponent = (
    <ul>
      {navigationConfig.map(({ href, label }) => (
        <li key={label}>
          <Link href={getI18nLink(href)} variant="list">
            {getI18nLabel({ label })}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <>  
      <FooterWrapper navigationComponent={navigationComponent}>
        <SubscribeToNewsletter language={language} />
        
        <Social titleText={getI18nLabel({ label: "footerSocialTitle" })}>
          <ul>
            {socialLinks.map(({ href, name, src, onClick }) => (
              <li key={name}>
                <Link href={href} onClick={onClick} target="_blank" rel="noreferrer" variant="list">
                  <img
                    src={`/images/${src}.svg`}
                    alt={name}
                    title={name}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </Social>
      </FooterWrapper>

      {showWeChatQR ? (
        <PhillipsModal customClasses={["phillips-modal__qrcode"]} hideModal={() => setShowWeChatQr(false)}>
          <div id="qr-code" className="show">
            <div id="qr-code-image-wechat" />
          </div>
        </PhillipsModal>
      ) : null}

      {showRedQR ? (
        <PhillipsModal customClasses={["phillips-modal__qrcode"]} hideModal={() => setRedQr(false)}>
          <div id="qr-code" className="show">
            <div id="qr-code-image-red" />
          </div>
        </PhillipsModal>
      ) : null}
    </>
  );
};

export default Footer;
